//@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,600,700&display=swap");
//@import url("https://fonts.googleapis.com/css?family=Cabin:400,500,600,700&display=swap");

body {
  margin: 0;
  //font-family: "IBM Plex Sans", sans-serif !important;
  //font-family: "Cabin", sans-serif !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 15px !important;
  background-color: rgb(238, 238, 238) !important;
  overflow-y: scroll;
}

.topbar {
  background-color: #c52525;
  color: #fff;
  height: 50px;
  line-height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  // box-shadow: 0px 2px 6px #808080;

  h2 {
    font-size: 16px;
    margin: 0;
    margin-left: 50px + 10px;
    color: inherit;
  }

  .back {
    background-color: transparent;
    width: 50px;
    height: inherit;
    float: left;
    text-align: center;
    border: none;
    outline: none;
  }
}

.landing-page-mobile {
  .title {
    text-align: center;
    font-size: 17px;
    background-color: #c52525;
    color: #fff;
    margin: 0;
    padding: 10px 0;
    line-height: 1em;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    // box-shadow: 0px 2px 6px #808080;

    button.cart {
      position: absolute;
      z-index: 20;
      top: 0;
      right: 0;
      background-color: transparent;
      border: none;
      color: #fff;
      height: 55px;
      width: 55px;
      outline: none;

      &:active {
        background-color: #b62121;
      }
    }

    button.pengenalan {
      position: absolute;
      z-index: 20;
      top: 0;
      left: 0;
      background-color: transparent;
      border: none;
      color: #fff;
      height: 55px;
      width: 55px;
      outline: none;

      &:active {
        background-color: #b62121;
      }
    }
  }

  .top {
    text-align: center;
    background-color: #fff;

    .logo {
      height: 100px;
    }
  }

  .hadis {
    background-color: #c52525;
    color: #fff;
    font-size: 13px;
    padding: 15px;
    text-align: center;

    .riwayat {
      font-weight: bold;
      margin: 5px 0;
    }
  }

  .intro {
    background-color: #fff;
    margin-bottom: 10px;
    padding: 10px 20px;
  }

  .floating-action {
    position: fixed;
    bottom: 25px;
    left: 25px;
    right: 25px;
  }
}

.kawasan-panel {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  background-color: #fff;
  margin-bottom: 12px;
  user-select: none;

  .thumbnail {
    margin: 0 3px;
    img {
      border-radius: 50%;
      width: 48px;
      margin: 10px;
      border: 3px solid rgb(255, 255, 255);
      box-shadow: 0px 1px 1px rgb(167, 167, 167);
    }

    .badge {
      text-align: center;
      padding: 3px;
      margin: 3px 5px;
      display: block;
      border-radius: 5px;
      font-size: 11px;

      &.korban {
        background-color: #dce775;
        color: #827717;
      }

      &.akikah {
        background-color: #b3e5fc;
        color: #0277bd;
      }
    }
  }
  .content {
    //background-color: blue;
    flex: 1;
    padding: 10px 0;
    padding-right: 10px;

    h3 {
      margin: 0;
    }

    p {
      margin: 0;
    }

    .price {
      //background-color: green;
      display: flex;
      margin-top: 10px;

      .haiwan {
        flex: 1;
        text-align: center;
        border: 1px solid #eee;
        padding: 5px;
      }

      .cow {
        border-right: none;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        img {
          width: 24px;
        }
      }

      .goat {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        img {
          width: 24px;
        }
        //background-color: pink;
      }
    }

    .button-sumbang {
      border: 1px solid rgb(199, 52, 52);
      color: rgb(199, 52, 52);
      background: #fff;
      //padding: 5px 13px;
      //width: 100%;
      margin-top: 7px;
      border-radius: 5px;
      outline: none;
      //border: none;
      //box-shadow: 0px 2px 2px rgb(231, 231, 231);
      user-select: none;

      &:active {
        background-color: rgb(255, 212, 212);
      }
    }
  }
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}

button.butiran-pembayaran {
  &:active {
    background-color: rgb(241, 241, 241);
  }

  background-color: #fff;
  padding: 15px;
  margin-bottom: 2px;
  border: none;
  width: 100%;
  text-align: left;
  outline: none;
}

.pembayaran-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
  text-align: center;
}
.align-right {
  text-align: right !important;
}
.align-center {
  text-align: center !important;
}

.ant-card-head {
  min-height: unset !important;
}

table.form {
  .label {
    background-color: #eee;
  }

  td {
    padding: 5px 10px;
    border: 1px solid #fff;
  }
}

.sedekah-korban {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 15px;
  box-shadow: 0px 3px 5px #c4c4c4;
  border-radius: 5px;

  .content {
    display: grid;
    grid-template-columns: 80px 1fr;
    column-gap: 15px;
  }

  img {
    width: 100%;
  }

  h1 {
    font-size: 1.2em;
  }

  button {
    border: 1px solid #18608a;
    outline: none;
    color: #18608a;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
  }

  button:active {
    background-color: #18608a;
    color: #fff;
  }
}

ul.sedekah {
  padding: 0;
  list-style: none;

  li {
    padding: 10px;
    border-bottom: 1px dashed #ececee;
  }
}

.amaun-sedekah {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 10px;

  &.mobile {
    grid-template-columns: 1fr 1fr;

    button {
      margin-bottom: 10px;
    }
  }

  button {
    border: 1px solid #acacac;
    cursor: pointer;
    outline: none;
    height: 50px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,e5e5e5+100;White+3D */
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(top, #ffffff 0%, #e5e5e5 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      #ffffff 0%,
      #e5e5e5 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #ffffff 0%,
      #e5e5e5 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e5e5e5',GradientType=0 ); /* IE6-9 */

    &.active {
      background: #fff;
      font-weight: bold;
      border: 2px solid #c41a1a;
      border-radius: 5px;
      box-shadow: inset 0px 4px 5px 2px #cccccc;
    }
  }
}
.padam-sedekah {
  float: right;
  color: red;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}
