body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 15px !important;
  background-color: #eeeeee !important;
  overflow-y: scroll; }

.topbar {
  background-color: #c52525;
  color: #fff;
  height: 50px;
  line-height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2; }
  .topbar h2 {
    font-size: 16px;
    margin: 0;
    margin-left: 60px;
    color: inherit; }
  .topbar .back {
    background-color: transparent;
    width: 50px;
    height: inherit;
    float: left;
    text-align: center;
    border: none;
    outline: none; }

.landing-page-mobile .title {
  text-align: center;
  font-size: 17px;
  background-color: #c52525;
  color: #fff;
  margin: 0;
  padding: 10px 0;
  line-height: 1em;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10; }
  .landing-page-mobile .title button.cart {
    position: absolute;
    z-index: 20;
    top: 0;
    right: 0;
    background-color: transparent;
    border: none;
    color: #fff;
    height: 55px;
    width: 55px;
    outline: none; }
    .landing-page-mobile .title button.cart:active {
      background-color: #b62121; }
  .landing-page-mobile .title button.pengenalan {
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    background-color: transparent;
    border: none;
    color: #fff;
    height: 55px;
    width: 55px;
    outline: none; }
    .landing-page-mobile .title button.pengenalan:active {
      background-color: #b62121; }

.landing-page-mobile .top {
  text-align: center;
  background-color: #fff; }
  .landing-page-mobile .top .logo {
    height: 100px; }

.landing-page-mobile .hadis {
  background-color: #c52525;
  color: #fff;
  font-size: 13px;
  padding: 15px;
  text-align: center; }
  .landing-page-mobile .hadis .riwayat {
    font-weight: bold;
    margin: 5px 0; }

.landing-page-mobile .intro {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 10px 20px; }

.landing-page-mobile .floating-action {
  position: fixed;
  bottom: 25px;
  left: 25px;
  right: 25px; }

.kawasan-panel {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  background-color: #fff;
  margin-bottom: 12px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .kawasan-panel .thumbnail {
    margin: 0 3px; }
    .kawasan-panel .thumbnail img {
      border-radius: 50%;
      width: 48px;
      margin: 10px;
      border: 3px solid white;
      box-shadow: 0px 1px 1px #a7a7a7; }
    .kawasan-panel .thumbnail .badge {
      text-align: center;
      padding: 3px;
      margin: 3px 5px;
      display: block;
      border-radius: 5px;
      font-size: 11px; }
      .kawasan-panel .thumbnail .badge.korban {
        background-color: #dce775;
        color: #827717; }
      .kawasan-panel .thumbnail .badge.akikah {
        background-color: #b3e5fc;
        color: #0277bd; }
  .kawasan-panel .content {
    flex: 1 1;
    padding: 10px 0;
    padding-right: 10px; }
    .kawasan-panel .content h3 {
      margin: 0; }
    .kawasan-panel .content p {
      margin: 0; }
    .kawasan-panel .content .price {
      display: flex;
      margin-top: 10px; }
      .kawasan-panel .content .price .haiwan {
        flex: 1 1;
        text-align: center;
        border: 1px solid #eee;
        padding: 5px; }
      .kawasan-panel .content .price .cow {
        border-right: none;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px; }
        .kawasan-panel .content .price .cow img {
          width: 24px; }
      .kawasan-panel .content .price .goat {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px; }
        .kawasan-panel .content .price .goat img {
          width: 24px; }
    .kawasan-panel .content .button-sumbang {
      border: 1px solid #c73434;
      color: #c73434;
      background: #fff;
      margin-top: 7px;
      border-radius: 5px;
      outline: none;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
      .kawasan-panel .content .button-sumbang:active {
        background-color: #ffd4d4; }

.switch-wrapper {
  position: relative; }

.switch-wrapper > div {
  position: absolute; }

button.butiran-pembayaran {
  background-color: #fff;
  padding: 15px;
  margin-bottom: 2px;
  border: none;
  width: 100%;
  text-align: left;
  outline: none; }
  button.butiran-pembayaran:active {
    background-color: #f1f1f1; }

.pembayaran-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
  text-align: center; }

.align-right {
  text-align: right !important; }

.align-center {
  text-align: center !important; }

.ant-card-head {
  min-height: unset !important; }

table.form .label {
  background-color: #eee; }

table.form td {
  padding: 5px 10px;
  border: 1px solid #fff; }

.sedekah-korban {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 15px;
  box-shadow: 0px 3px 5px #c4c4c4;
  border-radius: 5px; }
  .sedekah-korban .content {
    display: grid;
    grid-template-columns: 80px 1fr;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px; }
  .sedekah-korban img {
    width: 100%; }
  .sedekah-korban h1 {
    font-size: 1.2em; }
  .sedekah-korban button {
    border: 1px solid #18608a;
    outline: none;
    color: #18608a;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    cursor: pointer; }
  .sedekah-korban button:active {
    background-color: #18608a;
    color: #fff; }

ul.sedekah {
  padding: 0;
  list-style: none; }
  ul.sedekah li {
    padding: 10px;
    border-bottom: 1px dashed #ececee; }

.amaun-sedekah {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px; }
  .amaun-sedekah.mobile {
    grid-template-columns: 1fr 1fr; }
    .amaun-sedekah.mobile button {
      margin-bottom: 10px; }
  .amaun-sedekah button {
    border: 1px solid #acacac;
    cursor: pointer;
    outline: none;
    height: 50px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,e5e5e5+100;White+3D */
    background: #ffffff;
    /* Old browsers */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e5e5e5',GradientType=0 );
    /* IE6-9 */ }
    .amaun-sedekah button.active {
      background: #fff;
      font-weight: bold;
      border: 2px solid #c41a1a;
      border-radius: 5px;
      box-shadow: inset 0px 4px 5px 2px #cccccc; }

.padam-sedekah {
  float: right;
  color: red;
  border: none;
  outline: none;
  background: none;
  cursor: pointer; }

